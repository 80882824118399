import {ActionCreatorDefinition, createAction} from "redux-ts";
import {LangCode} from "platform/enum/LangCode";

export const LoadLanguageType: string =  "LoadLanguage";
export const SetDetectedLangCodeType: string =  "SetDetectedLangCode";
export const SetLangCodeType: string =  "SetLangCode";
export const SetSupportedLanguagesType: string =  "SetSupportedLanguages";

export interface LoadLanguagePayload {

    langCode: LangCode;
}

export interface SetDetectedLangCodePayload {

    langCode: LangCode;
}

export interface SetLangCodePayload {

    langCode: LangCode;
    data: any;
}

export interface SetSupportedLanguagesPayload {

    languages: LangCode[];
}

export const LoadLanguage: ActionCreatorDefinition<LoadLanguagePayload, any> = createAction<LoadLanguagePayload>(LoadLanguageType);
export const SetDetectedLangCode: ActionCreatorDefinition<SetDetectedLangCodePayload, any> = createAction<SetDetectedLangCodePayload>(SetDetectedLangCodeType);
export const SetLangCode: ActionCreatorDefinition<SetLangCodePayload, any> = createAction<SetLangCodePayload>(SetLangCodeType);
export const SetSupportedLanguages: ActionCreatorDefinition<SetSupportedLanguagesPayload, any> = createAction<SetSupportedLanguagesPayload>(SetSupportedLanguagesType);
