import Utils from "platform/util/Utils";
import {Logger} from "platform/logger/Logger";
import {LangCode} from "platform/enum/LangCode";
import {LanguageUtil} from "platform/util/LanguageUtil";
import {ShowChatPayload} from "platform/redux/core/CoreActions";

export class ZenDeskChat {

    private static LOGGER: Logger = Logger.Of("ZenDeskChat");
    private static _label: any;
    private static _detectTimeoutHandler: any;
    private static _detectIntervalHandler: any;
    private static _showTimeoutHandler: any;
    private static _showIntervalHandler: any;
    private static _deferred: ShowChatPayload;

    private constructor() {}

    public static detectAndHide(callback: () => void): void {
        if (Utils.isNull(ZenDeskChat._detectTimeoutHandler)) {
            ZenDeskChat._detectTimeoutHandler = setTimeout(() => {
                clearInterval(ZenDeskChat._detectIntervalHandler);
            }, 10000);
            ZenDeskChat._detectIntervalHandler = setInterval(() => {
                const zE: any = (window as any).zE;
                const $zopim: any = (window as any).$zopim;
                if (Utils.isNotNull(zE) && Utils.isNotNull($zopim) && Utils.isNotNull($zopim.livechat)) {
                    $zopim.livechat.setOnConnected(() => {
                        if (ZenDeskChat._deferred) {
                            setTimeout(() => {
                                ZenDeskChat.show(ZenDeskChat._deferred);
                                ZenDeskChat._deferred = null;
                            }, 1000);
                        }
                    });
                    ZenDeskChat.LOGGER.debug("Detected ZenDesk chat");
                    zE("webWidget", "hide");
                    clearInterval(ZenDeskChat._detectIntervalHandler);
                    clearTimeout(ZenDeskChat._detectTimeoutHandler);
                    if (callback) {
                        callback();
                    }
                }
            }, 500);
        }
    }

    public static init(email: string = "", fName: string = "", lName: string = ""): void {
        const zE: any = (window as any).zE;
        if (Utils.isNotNull(zE)) {
            ZenDeskChat.LOGGER.debug("Setting ZenDesk chat info for: " + email);
            let userName: string = email;
            if (Utils.isNotEmpty(fName) || Utils.isNotEmpty(lName)) {
                userName = `${fName} ${lName}`;
            }
            userName = userName ? userName.trim() : "";
            zE("webWidget", "identify", {name: userName, email});
        } else {
            ZenDeskChat.LOGGER.debug("Can't init ZenDesk chat. Object not available");
        }
    }

    public static show(payload: ShowChatPayload): void {
        const zE: any = (window as any).zE;
        if (Utils.isNotNull(zE)) {
            zE("webWidget", "open");
            zE("webWidget", "show");
            zE("webWidget:on", "close", ZenDeskChat.hide);
            const {tags = [], message, opposite} = payload;
            const langCode: LangCode = LanguageUtil.languageCode();
            ZenDeskChat.setDepartment(langCode, tags);
            const $zopim: any = (window as any).$zopim;
            if (Utils.isNotNull($zopim) && Utils.isNotNull($zopim.livechat)) {
                if (Utils.isNotEmpty(message) && Utils.isNotNull($zopim.livechat.say)) {
                    $zopim.livechat.say(message);
                }
            } else {
                ZenDeskChat.LOGGER.debug("No Z-chat $zopim or $zopim.livechat");
                ZenDeskChat._deferred = payload;
            }
            ZenDeskChat.changePosition(langCode, opposite);
        } else {
            ZenDeskChat.LOGGER.debug("Can't show ZenDesk chat. Object not available");
            ZenDeskChat._deferred = payload;
        }
    }

    private static setDepartment(langCode: LangCode, tags: string[]): void {
        const $zopim: any = (window as any).$zopim;
        if (Utils.isNotNull($zopim) && Utils.isNotNull($zopim.livechat)) {
            const tagsWithLang: string[] = (tags || []).concat(["language_" + langCode.toUpperCase()]);
            ZenDeskChat.LOGGER.info("Set tags: " + tagsWithLang);
            $zopim.livechat.removeTags();
            $zopim.livechat.addTags(tagsWithLang);
            const departmentName: string = (ZenDeskChat._label + "_" + langCode).toUpperCase();
            const department: any = $zopim.livechat.departments.getDepartment(departmentName);
            if (Utils.isNotNull(department)) {
                ZenDeskChat.LOGGER.info("Set department : " + departmentName + " Status: " + department.status);
                $zopim.livechat.departments.setVisitorDepartment(departmentName);
                if (department.status === "offline") {
                    $zopim.livechat.setStatus("offline");
                } else {
                    $zopim.livechat.setStatus("online");
                }
            } else {
                ZenDeskChat.LOGGER.warn("Can't find department with name: " + departmentName);
            }
        }
    }

    private static changePosition(langCode: LangCode, opposite?: boolean): void {
        if (Utils.isNull(ZenDeskChat._showTimeoutHandler)) {
            ZenDeskChat._showTimeoutHandler = setTimeout(() => {
                clearInterval(ZenDeskChat._showIntervalHandler);
                ZenDeskChat._showTimeoutHandler = null;
                ZenDeskChat._showIntervalHandler = null;
            }, 1000);
            ZenDeskChat._showIntervalHandler = setInterval(() => {
                const zEFrame: HTMLIFrameElement = document && document.getElementById("webWidget") as HTMLIFrameElement;
                if (zEFrame) {
                    clearInterval(ZenDeskChat._showIntervalHandler);
                    clearTimeout(ZenDeskChat._showTimeoutHandler);
                    ZenDeskChat._showTimeoutHandler = null;
                    ZenDeskChat._showIntervalHandler = null;
                    let isRtl: boolean = LangCode.rtlCode(langCode);
                    if (opposite) {
                        isRtl = !isRtl;
                    }
                    if (isRtl) {
                        zEFrame.setAttribute("style", zEFrame.getAttribute("style") + "left: 0px !important; right: unset !important;");
                    } else {
                        zEFrame.setAttribute("style", zEFrame.getAttribute("style") + "right: 0px !important; left: unset !important;");
                    }
                }
            }, 200);
        }
    }

    public static SetLangCode(langCode: LangCode): void {
        const zE: any = (window as any).zE;
        if (Utils.isNotNull(zE) && langCode) {
            zE("webWidget", "setLocale", langCode.toUpperCase());
            ZenDeskChat.setDepartment(langCode, []);
            ZenDeskChat.changePosition(langCode);
        }
    }

    public static setLangCode(langCode: LangCode, label: string): void {
        ZenDeskChat._label = label;
        ZenDeskChat.SetLangCode(langCode);
    }

    public static hide(): void {
        const zE: any = (window as any).zE;
        if (Utils.isNotNull(zE)) {
            zE("webWidget", "hide");
        } else {
            ZenDeskChat.LOGGER.debug("Can't hide ZenDesk chat. Object not available");
        }
    }

    public static openInWindow(): void {
        const $zopim: any = (window as any).$zopim;
        if (Utils.isNotNull($zopim)) {
            $zopim.livechat.window.openPopout();
        }
    }
}
