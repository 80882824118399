import {Engine} from "platform/engine/Engine";
import CoreEngine from "platform/engine/CoreEngine";
import WebUtil from "platform/util/WebUtil";
import {RedirectLoginPayload} from "core/redux/app/AppReduxActions";
import Platform from "platform/Platform";
import {Configuration} from "core/configuration/Configuration";
import Utils from "platform/util/Utils";

const DpkParam: string = WebUtil.urlParam("dpk");
const QueryStringRaw: string = window.location.search;

export default class AppEngine extends Engine {

    private static _instance: AppEngine;

    public static instance(): AppEngine {
        return this._instance || (this._instance = new this());
    }

    public async setup(): Promise<void> {
        await super.setup();
        CoreEngine.instance().setup().catch(() => {});
    }

    public doRedirectLogin = ({brokerName}: RedirectLoginPayload): void => {
        const loginUrls: {[key: string]: string} = Platform.config<Configuration>().loginUrl;
        if (loginUrls && loginUrls[brokerName]) {
            let url: string = loginUrls[brokerName];
            if (Utils.isNotEmpty(DpkParam)) {
                const parameters: {[key: string]: string} = WebUtil.getParamsToObject(QueryStringRaw);
                url = WebUtil.addGetParams(url, parameters);
            }
            Platform.environment().redirect(url);
        }
    }
}
