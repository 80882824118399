export default class GraphNode<T> {

    private readonly _value: T;
    private readonly _parent: GraphNode<T>;
    private _nodes: GraphNode<T>[] = [];

    constructor(value: T, parent?: GraphNode<T>) {
        this._value = value;
        this._parent = parent;
    }

    public addNode(value: T, parent?: GraphNode<T>): GraphNode<T> {
        const node: GraphNode<T> = GraphNode.create<T>(value, parent);
        this._nodes.push(node);
        return node;
    }

    public static create<T>(value: T, parent?: GraphNode<T>): GraphNode<T> {
        return new GraphNode<T>(value, parent);
    }

    public get value(): T {
        return this._value;
    }

    public get parent(): GraphNode<T> {
        return this._parent;
    }

    public hasNode(value: T): boolean {
        if (this._value === value) {
            return true;
        }
        for (const node of this._nodes) {
            if (node.value === value) {
                return true;
            }
        }
        return this._parent ? this._parent.hasNode(value) : false;
    }
}
