import {Logger, LogLevel} from "platform/logger/Logger";
import {ConfigurationLoader} from "platform/configuration/ConfigurationLoader";
import Utils from "platform/util/Utils";
import {LangCode} from "platform/enum/LangCode";
import {Configuration} from "core/configuration/Configuration";
import {Http} from "platform/network/http/Http";

export default class ConfigManager {

    private static _logger: Logger = Logger.Of("ConfigManager");
    private static _loader: ConfigurationLoader<Configuration>;
    private static _config: Configuration = {
        version: "1.0.0",
        logLevel: LogLevel.DEBUG,
        brand: "-",
        supportedLanguages: [LangCode.EN],
        brokers: [],
        loginUrl: {}
    };

    private constructor() {
    }

    public static loader(): ConfigurationLoader<Configuration> {
        if (Utils.isNull(this._loader)) {
            this._loader = {
                load(): Promise<Configuration> {
                    return new Promise<Configuration>(
                        (resolve, reject) => {
                            Http.getJson("resources/configuration/config.json").then((config: Configuration) => {
                                ConfigManager._logger.info("Configuration loaded");
                                try {
                                    const result: Configuration = Object.assign({}, ConfigManager._config, config);
                                    ConfigManager._config = result;
                                    resolve(result);
                                } catch (e) {
                                    ConfigManager._logger.warn("Failed to merge configuration. " + JSON.stringify(e) + " Going to use hardcoded configuration");
                                    resolve(ConfigManager._config);
                                }
                            }).catch((e) => {
                                ConfigManager._logger.warn("Failed to load configuration: " + JSON.stringify(e));
                                reject();
                            });
                        }
                    );
                }
            };
        }
        return this._loader;
    }
}
