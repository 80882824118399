enum BrandType {

    SeventyTrades = "_70Trades",
    A3Trading = "A3Trading",
    Financika = "Financika",
    Finansero = "Finansero",
    Fortissio = "Fortissio",
    FXGMZA = "FXGM-za",
    Ontega = "Ontega",
    Profit = "Profit",
    TaoTrade = "TaoTrade",
    ToroInvest = "ToroInvest",
    TradoCenter = "TradoCenter",
    UBanker = "UBanker",
    WBSmith = "WBSmith",
    XLNTrade = "XLNTrade",
    Zenstox = "Zenstox",
}

namespace BrandType {

    export function deserialize(type: string): BrandType {
        if (type === "70Trades") {
            return BrandType.SeventyTrades;
        } else if (type === "FXGM-za") {
            return BrandType.FXGMZA;
        } else if (type === "Warren Bowie & Smith") {
            return BrandType.WBSmith;
        }
        return BrandType[type] || type;
    }

    export function from(brand: string): BrandType {
        if (brand) {
            switch (brand) {
                case "70trades":
                    return BrandType.SeventyTrades;
                case "a3trading":
                    return BrandType.A3Trading;
                case "financika":
                    return BrandType.Financika;
                case "finansero":
                    return BrandType.Finansero;
                case "fortissio":
                    return BrandType.Fortissio;
                case "fxgmza":
                    return BrandType.FXGMZA;
                case "ontega":
                    return BrandType.Ontega;
                case "pro-fit":
                    return BrandType.Profit;
                case "taotrade":
                    return BrandType.TaoTrade;
                case "toroinvest":
                    return BrandType.ToroInvest;
                case "tradocenter":
                    return BrandType.TradoCenter;
                case "ubanker":
                    return BrandType.UBanker;
                case "wbandsmith":
                    return BrandType.WBSmith;
                case "xlntrade":
                    return BrandType.XLNTrade;
                case "zenstox":
                    return BrandType.Zenstox;
            }
        }
        return null;
    }

    export function folder(brandId: number): string {
        if (brandId) {
            switch (brandId) {
                case 39:
                    return "70trades";
                case 33:
                    return "a3trading";
                case 29:
                    return "financika";
                case 65:
                    return "finansero";
                case 41:
                    return "fortissio";
                case 56:
                    return "fxgmza";
                case 60:
                    return "ontega";
                case 66:
                    return "pro-fit";
                case 34:
                    return "taotrade";
                case 64:
                    return "toroinvest";
                case 62:
                    return "tradocenter";
                case 35:
                    return "ubanker";
                case 63:
                    return "wbandsmith";
                case 40:
                    return "xlntrade";
                case 67:
                    return "zenstox";
            }
        }
        return null;
    }

    export function id(type: BrandType): number {
        if (type) {
            switch (type) {
                case BrandType.SeventyTrades:
                    return 39;
                case BrandType.A3Trading:
                    return 33;
                case BrandType.Financika:
                    return 29;
                case BrandType.Finansero:
                    return 65;
                case BrandType.Fortissio:
                    return 41;
                case BrandType.FXGMZA:
                    return 56;
                case BrandType.Ontega:
                    return 60;
                case BrandType.Profit:
                    return 66;
                case BrandType.TaoTrade:
                    return 34;
                case BrandType.ToroInvest:
                    return 64;
                case BrandType.TradoCenter:
                    return 62;
                case BrandType.UBanker:
                    return 35;
                case BrandType.WBSmith:
                    return 63;
                case BrandType.XLNTrade:
                    return 40;
                case BrandType.Zenstox:
                    return 67;
            }
        }
        return null;
    }
}

export {BrandType};
