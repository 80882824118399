import {Environment} from "platform/environment/Environment";
import Utils from "platform/util/Utils";
import {Logger} from "platform/logger/Logger";
import {ReduxStore} from "platform/redux/ReduxStore";
import {Action, Middleware, ReducersMapObject, Store} from "redux";
import {Service} from "platform/service/Service";
import {Reducer} from "platform/redux/Reducer";
import {Storage} from "platform/storage/Storage";
import {TSMap} from "typescript-map";
import {PlatformReduxState} from "platform/redux/PlatformReduxState";
import ConnectionReducer from "platform/redux/connection/ConnectionReducer";
import {Dpk} from "platform/dpk/Dpk";
import {Audio} from "platform/audio/Audio";
import GlobalIntegration from "platform/integration/GlobalIntegration";
import WebUtil from "platform/util/WebUtil";
import {Navigator} from "platform/navigator/Navigator";
import TranslationReducer from "platform/redux/translation/TranslationReducer";
import PopupsReducer from "platform/redux/popups/PopupsReducer";
import {TranslationLoader} from "platform/translation/TranslationLoader";
import {LangCode} from "platform/enum/LangCode";
import Translations from "platform/translation/Translations";
import {ConfigurationLoader, PlatformConfiguration} from "platform/configuration/ConfigurationLoader";
import {IRouter} from "platform/router/IRouter";
import CoreReducer from "platform/redux/core/CoreReducer";
import {BrandType} from "platform/enum/BrandType";
import {SetBrand} from "platform/redux/core/CoreActions";
import {EnvType} from "platform/enum/EnvType";
import {BITracker} from "platform/analytics/BITracker";

export interface PlatformProps<ReduxState extends PlatformReduxState> {

    configurationLoader: ConfigurationLoader<PlatformConfiguration>;
    translationLoader?: TranslationLoader;
    environment: Environment;
    services?: {service: Service<any, any>, props?: any}[];
    router?: IRouter;
    startPath?: string;
    initialState?: ReduxState;
    reducersMap?: ReducersMapObject;
    middleWares?: Middleware[];
}

export interface SetupResult {

    configuration?: PlatformConfiguration;
    routerState?: any;
}

export default class Platform {

    private static _logger: Logger = Logger.Of("Platform");
    private static _configuration: PlatformConfiguration;
    private static _services: TSMap<string, Service<any, any>> = new TSMap();
    private static _props: PlatformProps<any>;
    private static _reduxStore: ReduxStore<any>;

    private constructor() {
    }

    public static async setup<ReduxState extends PlatformReduxState>(props: PlatformProps<ReduxState>): Promise<SetupResult> {
        Utils.checkNotNull(props.configurationLoader);
        this._logger.info("Start load configuration");
        const hasRouter: boolean = Utils.isNotNull(props.router);
        const configAnswer: any = await Utils.to(props.configurationLoader.load());
        if (Utils.isNotNull(configAnswer[0])) {
            this._logger.error("Failed to load configuration.");
            return Promise.reject(configAnswer[0]);
        }
        this._logger.info("Setup platform");
        this._configuration = configAnswer[1];
        const logLevelParam: string = WebUtil.urlParam("logLevel");
        Logger.setLogLevel(logLevelParam || this._configuration.logLevel);
        this._props = props;
        const navigator: Navigator = Platform.navigator();
        if (navigator) {
            navigator.init();
        }
        if (props.environment.type() === EnvType.Web) {
            GlobalIntegration.instance().setInitialized(true);
        }
        let reducers: Reducer<any>[] = new Array<Reducer<any>>();
        reducers.push(
            CoreReducer.instance(),
            ConnectionReducer.instance(),
            TranslationReducer.instance(),
            PopupsReducer.instance()
        );
        this._services = new TSMap<string, Service<any, any>>();
        if (Utils.isArrayNotEmpty(props.services)) {
            props.services.forEach((ser: { service: Service<any, any>, props: any }) => {
                this._services.set(ser.service.name(), ser.service);
                if (Utils.isArrayNotEmpty(ser.service.reducers())) {
                    reducers = reducers.concat(ser.service.reducers());
                }
            });
        }
        let middleWares: Middleware[] = [
            Dpk.middleware()
        ];
        const audio: Audio = Platform.audio();
        if (audio) {
            middleWares.push(audio.middleware());
        }
        if (hasRouter) {
            middleWares.push(props.router.middleware());
        }
        if (Utils.isArrayNotEmpty(props.middleWares)) {
            middleWares = middleWares.concat(props.middleWares);
        }
        const reducersMap: ReducersMapObject = props.reducersMap || {};
        if (hasRouter) {
            reducersMap.router = props.router.reducer();
        }
        this._logger.info("Creating redux store");
        this._reduxStore = new ReduxStore(reducersMap, reducers, middleWares, props.initialState);
        const brand: BrandType = BrandType.deserialize(this._configuration.brand);
        if (brand) {
            this.dispatch(SetBrand({brandType: brand}));
        }
        // NOTE: Services setup should be the last step during Platform setup after ReduxStore, Networks etc initialized
        this._logger.info("Setup services");
        if (Utils.isArrayNotEmpty(props.services)) {
            for (let i = 0; i < props.services.length; i++) {
                const ser = props.services[i];
                await ser.service.setup(ser.props);
            }
        }
        const result: SetupResult = {
            configuration: this._configuration
        };
        if (hasRouter) {
            this._logger.info("Start router");
            const routerAnswer: any = await Utils.to(props.router.start(props.startPath));
            const routerError: any = routerAnswer[0];
            if (Utils.isNotNull(routerError)) {
                return Promise.reject(routerError);
            } else {
                result.routerState = routerAnswer[1];
            }
        }
        return Promise.resolve(result);
    }

    public static config<C extends PlatformConfiguration>(): C {
        Utils.checkNotNull(this._configuration);
        return this._configuration as C;
    }

    public static environment(): Environment {
        Utils.checkNotNull(this._props);
        return this._props.environment;
    }

    public static navigator(): Navigator {
        return this.environment().navigator();
    }

    public static audio(): Audio {
        return this.environment().audio();
    }

    public static storage(): Storage {
        return this.environment().storage();
    }

    public static bi(): BITracker {
        return this.environment().bi();
    }

    public static router(): IRouter {
        Utils.checkNotNull(this._props);
        Utils.checkNotNull(this._props.router);
        return this._props.router;
    }

    public static loadTranslation(langCode: LangCode): Promise<{langCode: LangCode, data: any}> {
        Utils.checkNotNull(this._props);
        Utils.checkNotNull(this._props.translationLoader);
        return Translations.load(langCode, this._props.translationLoader);
    }

    public static store<ReduxState extends PlatformReduxState>(): Store<ReduxState> {
        Utils.checkNotNull(this._reduxStore);
        return this._reduxStore.store();
    }

    public static subscribeStore<State extends PlatformReduxState>(listener: (s: State) => void, path?: string): () => void {
        Utils.checkNotNull(this._reduxStore);
        return this._reduxStore.subscribe(listener, path);
    }

    public static dispatch<A extends Action>(action: A): void {
        this.store().dispatch(action);
    }

    public static reduxState<ReduxState extends PlatformReduxState>(): ReduxState {
        return this.store<ReduxState>().getState();
    }

    public static engine<Engine>(serviceName: string): Engine {
        const service: Service<any, any> = this._services.get(serviceName);
        Utils.checkNotNull(service);
        return service.engine();
    }

    public static state<State>(serviceName: string): State {
        const service: Service<any, any> = this._services.get(serviceName);
        Utils.checkNotNull(service);
        return service.state();
    }
}
