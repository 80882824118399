import {Action, ReducerBuilder} from "redux-ts";
import {Reducer} from "platform/redux/Reducer";
import {Popup, PopupsReduxState} from "platform/redux/popups/PopupsReduxState";
import {
    CloseAllPopups,
    HidePopup,
    HidePopupPayload,
    ShowPopup,
    ShowPopupPayload
} from "platform/redux/popups/PopupsActions";
import Utils from "platform/util/Utils";
import {Increment} from "platform/util/Increment";

export default class PopupsReducer extends Reducer<PopupsReduxState> {

    private static _instance: PopupsReducer;
    private readonly _popupId: Increment = Increment.from(0);

    private constructor() {
        super();
    }

    public static instance(): PopupsReducer {
        return this._instance || (this._instance = new this());
    }

    public get name(): string {
        return "popups";
    }

    protected setup(builder: ReducerBuilder<PopupsReduxState>): void {
        builder
            .init({
                popups: []
            })
            .handle(ShowPopup, (state: PopupsReduxState, {payload}: Action<ShowPopupPayload>) => {
                const newState: PopupsReduxState = Utils.merge({}, state);
                payload.popup.id = this._popupId.next();
                newState.popups = [...state.popups, payload.popup];
                return newState;
            })
            .handle(HidePopup, (state: PopupsReduxState, {payload}: Action<HidePopupPayload>) => {
                const newState: PopupsReduxState = Utils.merge({}, state);
                newState.popups = newState.popups.filter((popup: Popup) => {
                    return popup.id !== payload.popup.id;
                });
                return newState;
            })
            .handle(CloseAllPopups, (state: PopupsReduxState, action: Action<any>) => {
                this._logger.debug("Hide all popups");
                const newState: PopupsReduxState = Utils.merge({}, state);
                newState.popups = [];
                return newState;
            });
    }
}
