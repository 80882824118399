import {TSMap} from "typescript-map";
import {Dispatch, Middleware, MiddlewareAPI} from "redux";
import Utils from "platform/util/Utils";
import {ExecuteDpk, ExecuteDpkPayload, ExecuteDpkType} from "platform/redux/dpk/DpkActions";
import {Logger} from "platform/logger/Logger";
import Platform from "platform/Platform";
import Parameter from "platform/util/Parameter";
import {Action} from "redux-ts";

export const DPK: string = "dpk:";
export const DPK_ENCODED: string = "dpk%3A";

export interface IDpkHandler<T extends string> {

    dpkTypes(): T[];

    handle(dpkType: T, parameters: TSMap<string, any>, params: Parameter[]): void;
}

export class Dpk {

    private static _logger: Logger = Logger.Of("Dpk");
    private static _handlers: TSMap<string, IDpkHandler<string>> = new TSMap();
    private static _defaultParameters: TSMap<string, any> = new TSMap();
    private static _middleware: Middleware;

    private constructor() {
    }

    public static build<T extends string>(type: T, ...parameters: Parameter[]): string {
        if (Utils.isNotNull(type)) {
            let result: string = DPK + type;
            if (Utils.isArrayNotEmpty(parameters)) {
                parameters.forEach((parameter: Parameter) => {
                    result = result + "&" + parameter.key + "=" + parameter.value;
                });
            }
            return result;
        }
        return null;
    }

    public static setHandler<T extends string>(handler: IDpkHandler<T>): void {
        if (Utils.isNotNull(handler) && Utils.isArrayNotEmpty(handler.dpkTypes())) {
            handler.dpkTypes().forEach((dpkType: T) => {
                this._handlers.set(dpkType, handler);
            });
        }
    }

    public static addDefaultParameter(key: string, value: any): void {
        if (Utils.isNotNull(key)) {
            this._defaultParameters.set(key, value);
        }
    }

    public static middleware<State>(): Middleware {
        if (Utils.isNull(this._middleware)) {
            (window as any).dpk = (dpk: string) => {
                Platform.dispatch(ExecuteDpk({dpk}));
            };
            this._middleware = (api: MiddlewareAPI<any>) => (next: Dispatch<Action>) => <A extends Action>(action: A): A => {
                if (action.type === ExecuteDpkType) {
                    const executeDpk: ExecuteDpkPayload = action.payload;
                    if (Utils.isNotEmpty(executeDpk.dpk)) {
                        const dpkPrefix: string = executeDpk.dpk.indexOf(DPK) === 0 ? DPK
                            : executeDpk.dpk.indexOf(DPK_ENCODED) === 0 ? DPK_ENCODED : null;
                        if (Utils.isNotEmpty(dpkPrefix)) {
                            this._logger.debug("Try to execute dpk: " + executeDpk.dpk);
                            let index: number = executeDpk.dpk.indexOf("&amp;");
                            if (index < 0) {
                                index = executeDpk.dpk.indexOf("&");
                            }
                            const dpkType: string = executeDpk.dpk.substring(dpkPrefix.length, index > 0 ? index : executeDpk.dpk.length).trim();
                            const handler: IDpkHandler<string> = this._handlers.get(dpkType);
                            if (Utils.isNotNull(handler)) {
                                const parameters: TSMap<string, any> = this.parseParameters(index > 0 ? executeDpk.dpk.substring(index + 1) : null);
                                this._logger.debug("Execute dpk: " + dpkType + " Parameters: " + JSON.stringify(parameters));
                                handler.handle(dpkType, parameters, executeDpk.params);
                            } else {
                                this._logger.warn("Dpk handler not registered for command: " + dpkType + " Dpk config: " + executeDpk.dpk);
                            }
                        } else {
                            this._logger.warn("Dpk command should start with " + DPK + " Received command: " + executeDpk.dpk);
                        }
                    } else {
                        this._logger.warn("Dpk command empty");
                    }
                }
                return next(action);
            };
        }
        return this._middleware;
    }

    private static parseParameters(query: string): TSMap<string, any> {
        const parameters: TSMap<string, any> = this._defaultParameters.clone();
        if (Utils.isNotEmpty(query)) {
            const queryParameters: string[] = query.split(query.indexOf("&amp;") > 0 ? "&amp;" : "&");
            if (Utils.isArrayNotEmpty(queryParameters)) {
                queryParameters.forEach((parameter: string) => {
                    const index: number = parameter.indexOf("=");
                    if (index > 0) {
                        const key: string = parameter.substring(0, index);
                        const value: string = parameter.substring(index + 1);
                        if (Utils.isNotEmpty(key) && Utils.isNotEmpty(value)) {
                            const k: string = key.trim();
                            const v: string = value.trim();
                            parameters.set(k, v);
                        }
                    }
                });
            }
        }
        return parameters;
    }
}
