import Cookies from "js-cookie";
import {Action, ReducerBuilder} from "redux-ts";
import {Reducer} from "platform/redux/Reducer";
import {CoreReduxState} from "platform/redux/core/CoreReduxState";
import {BrandType} from "platform/enum/BrandType";
import {
    CloseCookieNotification,
    DisableLoaderOverlay,
    DisplayLogs,
    DisplayLogsPayload,
    HideLoader,
    InitChatType,
    NavigateToType,
    SetAppReady,
    SetAppReadyPayload,
    SetAppReadyType,
    SetBrand,
    SetBrandPayload,
    SetBrandProps,
    SetBrandPropsPayload,
    SetCookieAccepted,
    SetException,
    SetExceptionPayload,
    SetLoader,
    SetLoaderPayload,
    SetOrientation,
    SetOrientationPayload, SetTheme, SetThemeTypePayload,
    SetUrls,
    SetUrlsPayload,
    ShowChatType
} from "platform/redux/core/CoreActions";
import {TSMap} from "typescript-map";
import {UrlType} from "platform/enum/UrlType";
import CoreEngine from "platform/engine/CoreEngine";
import {
    LoadLanguageType,
    SetLangCode,
    SetLangCodePayload,
    SetLangCodeType
} from "platform/redux/translation/TranslationActions";
import {LangCode} from "platform/enum/LangCode";
import Utils from "platform/util/Utils";
import {CookieName} from "platform/enum/CookieName";
import {ThemeType} from "platform/enum/ThemeType";
import WebUtil from "platform/util/WebUtil";

const themeType: ThemeType = ThemeType.deserialize(WebUtil.urlParam("theme"));

export default class CoreReducer extends Reducer<CoreReduxState> {

    private static _instance: CoreReducer;

    private constructor() {
        super();
        const coreEngine: CoreEngine = CoreEngine.instance();
        this._middlewareActions.set(SetAppReadyType, coreEngine.onAppReady);
        this._middlewareActions.set(LoadLanguageType, coreEngine.onLoadLanguage);
        this._middlewareActions.set(SetLangCodeType, coreEngine.onSetLangCode);
        this._middlewareActions.set(InitChatType, coreEngine.initChat);
        this._middlewareActions.set(ShowChatType, coreEngine.showChat);
        this._middlewareActions.set(NavigateToType, coreEngine.doNavigateTo);
    }

    public static instance(): CoreReducer {
        return this._instance || (this._instance = new this());
    }

    public get name(): string {
        return "core";
    }

    protected setup(builder: ReducerBuilder<CoreReduxState>): void {
        builder
            .init({
                cookieNotification: !Utils.parseBoolean(Cookies.get(CookieName.AcceptCookie)),
                theme: themeType || ThemeType.Light,
                brand: BrandType.Profit,
                brandProps: {
                    keys: {}
                },
                urls: new TSMap<UrlType, string>(),
                exceptions: new TSMap(),
                loaderOverlay: true
            })
            .handle(SetTheme, (state: CoreReduxState, {payload}: Action<SetThemeTypePayload>) => {
                this._logger.debug("Set theme to: " + payload.themeType);
                return Object.assign({}, state, {
                    theme: payload.themeType
                });
            })
            .handle(SetOrientation, (state: CoreReduxState, {payload}: Action<SetOrientationPayload>) => {
                return Object.assign({}, state, {
                    orientation: payload.orientation
                });
            })
            .handle(SetAppReady, (state: CoreReduxState, {payload}: Action<SetAppReadyPayload>) => {
                return Object.assign({}, state, {
                    ready: payload.ready
                });
            })
            .handle(SetException, (state: CoreReduxState, {payload}: Action<SetExceptionPayload>) => {
                const newState: CoreReduxState = Utils.merge({}, state);
                newState.exceptions.set(payload.exceptionType, payload.error);
                return newState;
            })
            .handle(SetCookieAccepted, (state: CoreReduxState, action: Action<any>) => {
                Cookies.set(CookieName.AcceptCookie, "true");
                return Object.assign({}, state, {
                    cookieNotification: false
                });
            })
            .handle(CloseCookieNotification, (state: CoreReduxState, action: Action<any>) => {
                return Object.assign({}, state, {
                    cookieNotification: false
                });
            })
            .handle(SetLangCode, (state: CoreReduxState, {payload}: Action<SetLangCodePayload>) => {
                return Object.assign({}, state, {
                    rtl: LangCode.rtlCode(payload.langCode)
                });
            })
            .handle(SetBrand, (state: CoreReduxState, {payload}: Action<SetBrandPayload>) => {
                return Object.assign({}, state, {
                    brand: payload.brandType
                });
            })
            .handle(SetBrandProps, (state: CoreReduxState, {payload}: Action<SetBrandPropsPayload>) => {
                return Object.assign({}, state, {
                    brandProps: payload.brandProps
                });
            })
            .handle(SetUrls, (state: CoreReduxState, {payload}: Action<SetUrlsPayload>) => {
                const urls: TSMap<UrlType, string> = state.urls.clone();
                if (payload.urls) {
                    payload.urls.forEach((urlDefinition: { type: UrlType, url: string }) => {
                        urls.set(urlDefinition.type, urlDefinition.url);
                    });
                }
                return Object.assign({}, state, {
                    urls
                });
            })
            .handle(SetLoader, (state: CoreReduxState, {payload}: Action<SetLoaderPayload>) => {
                return Object.assign({}, state, {
                    loaderType: payload.loaderType
                });
            })
            .handle(HideLoader, (state: CoreReduxState, action: Action<any>) => {
                return Object.assign({}, state, {
                    loaderType: null
                });
            })
            .handle(DisableLoaderOverlay, (state: CoreReduxState, action: Action<any>) => {
                return Object.assign({}, state, {
                    loaderOverlay: false
                });
            })
            .handle(DisplayLogs, (state: CoreReduxState, {payload}: Action<DisplayLogsPayload>) => {
                return Object.assign({}, state, {
                    displayLogs: payload.display
                });
            });
    }
}
