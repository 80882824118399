import {ActionCreatorDefinition, createAction} from "redux-ts";
import {BrokerInfo} from "core/configuration/Configuration";

export const SelectBrokerType: string =  "SelectBroker";
export const RedirectLoginType: string =  "RedirectLogin";

export interface SelectBrokerPayload {

    broker: BrokerInfo;
}

export interface RedirectLoginPayload {

    brokerName: string;
}

export const SelectBroker: ActionCreatorDefinition<SelectBrokerPayload, any> = createAction<SelectBrokerPayload>(SelectBrokerType);
export const RedirectLogin: ActionCreatorDefinition<RedirectLoginPayload, any> = createAction<RedirectLoginPayload>(RedirectLoginType);
