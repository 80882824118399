import {Environment} from "platform/enum/Environment";
import {Logger} from "platform/logger/Logger";
import {BITracker} from "platform/analytics/BITracker";

export class BITrackerWeb implements BITracker {

    private static _instance: BITracker;
    private readonly _logger: Logger = Logger.Of("BITracker");
    private _loaded: boolean;
    private _initialized: boolean;
    private _identified: boolean;
    private _userId: number;

    private constructor() {
    }

    public static instance(): BITracker {
        return this._instance || (this._instance = new this());
    }

    public init = (token: string) => {
        if (!this._initialized) {
            this._initialized = true;
            this.load().then((mixpanel) => {
                this._logger.debug("Init");
                mixpanel.init(token, {
                    debug: !Environment.isProd(),
                    loaded: () => {
                        this._logger.debug("Loaded");
                        this._loaded = true;
                        if (this._userId) {
                            this.identify(this._userId);
                        }
                    }
                });
            });
        }
    }

    public identify = (userId: number) => {
        if (this._loaded && !this._identified) {
            this._identified = true;
            this.load().then((mixpanel) => {
                this._logger.debug("Identify");
                mixpanel.identify(userId);
            });
        } else {
            this._userId = userId;
        }
    }

    public track = (event: string, data?: any) => {
        if (this._identified) {
            this.load().then((mixpanel) => {
                mixpanel.track(event, data);
            });
        }
    }

    private load = async () => {
        return await import('mixpanel-browser');
    }
}
