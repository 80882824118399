import Utils from "platform/util/Utils";
import {Logger} from "platform/logger/Logger";
import IntegrationBridgeFactory from "platform/integration/bridge/IntegrationBridgeFactory";
import {IntegrationBridge} from "platform/integration/bridge/IntegrationBridge";
import {ConnectionListener} from "platform/listener/ConnectionListener";
import {IntegrationConnection} from "platform/integration/connection/IntegrationConnection";
import {IntegrationBridgeType} from "platform/integration/bridge/IntegrationBridgeType";
import {WebWindow} from "platform/integration/win/WebWindow";
import {EventType} from "platform/enum/EventType";
import WebUtil from "platform/util/WebUtil";

const platform: string = WebUtil.urlParam("platform");

export default class PostMessageConnection<MessageType> implements IntegrationConnection<MessageType> {

    private readonly _logger: Logger = Logger.Of(this.constructor.toString().match(/\w+/g)[1]);
    private readonly _sender: WebWindow;
    private readonly _receiver: WebWindow;
    private readonly _listener: ConnectionListener<MessageType>;
    private _bridge: IntegrationBridge;
    private _postMessageListener: (event: MessageEvent) => void;

    constructor(sender: WebWindow, receiver: WebWindow, listener: ConnectionListener<MessageType>) {
        this._sender = sender;
        this._receiver = receiver;
        this._listener = listener;
    }

    public connected(): boolean {
        return Utils.isNotNull(this._postMessageListener);
    }

    public connect(): void {
        if (Utils.isNull(this._postMessageListener)) {
            this._logger.debug("Create bridge for platform: " + platform);
            this._bridge = IntegrationBridgeFactory.createBridge(this._sender.origin(), this._receiver);
            (platform === "android" ? document : this._sender).addEventListener(EventType.Message, this._postMessageListener = this._onMessage.bind(this));
            if (Utils.isNotNull(this._listener) && Utils.isNotNull(this._listener.onConnected)) {
                this._listener.onConnected();
            }
        }
    }

    public disconnect(): void {
        if (Utils.isNotNull(this._postMessageListener)) {
            this._sender.removeEventListener(EventType.Message, this._postMessageListener);
            this._postMessageListener = null;
            if (Utils.isNotNull(this._listener) && Utils.isNotNull(this._listener.onDisconnected)) {
                this._listener.onDisconnected();
            }
        }
    }

    public type(): IntegrationBridgeType {
        if (Utils.isNotNull(this._bridge)) {
            return this._bridge.type();
        }
        return null;
    }

    private skipMessage(event: MessageEvent): boolean {
        const hasData: boolean = Utils.isNotNull(event.data);
        if (hasData) {
            if (Utils.isNotNull(event.data.source) && event.data.source.indexOf("react-devtools") === 0) {
                return true;
            }
            if (Utils.isNotNull(event.data.type) && event.data.type === "webpackOk") {
                return true;
            }
            if (Utils.isNotNull(event.data.indexOf) && event.data.indexOf("setImmediate") === 0) {
                return true;
            }
        }
        return !hasData;
    }

    private _onMessage(event: MessageEvent): void {
        if (!this.skipMessage(event)) {
            let jsObject: any;
            try {
                jsObject = JSON.parse(event.data);
            } catch (e) {
            }
            if (Utils.isNotNull(jsObject) && Utils.isNotNull(this._listener) && Utils.isNotNull(this._listener.onMessage)) {
                this._listener.onMessage(jsObject, event.origin);
            }
        }
    }

    public sendMessage(message: MessageType): void {
        if (this.connected()) {
            if (Utils.isNotNull(this._bridge)) {
                try {
                    this._bridge.sendMessage(JSON.stringify(message, null, 2));
                } catch (e) {
                    this._logger.warn("Error send post message: ", JSON.stringify(e));
                }
            } else {
                this._logger.warn("Bridge absent");
            }
        } else {
            this._logger.warn("Connection absent");
        }
    }

}
