import {Dispatch, Middleware, MiddlewareAPI} from "redux";
import {Logger} from "platform/logger/Logger";
import Utils from "platform/util/Utils";
import {PlaySoundPayload, PlaySoundType} from "platform/redux/audio/AudioActions";
import {Audio} from "platform/audio/Audio";
import {Action} from "redux-ts";
import {TSMap} from "typescript-map";

export class WebAudio implements Audio {

    private static _instance: WebAudio;
    private _logger: Logger = Logger.Of("Audio");
    private _buffers: TSMap<string, AudioBuffer> = new TSMap<string, AudioBuffer>();
    private _audioCtx;
    private _middleware: Middleware;

    public static instance(): WebAudio {
        return this._instance || (this._instance = new this());
    }

    public middleware<State>(): Middleware {
        if (Utils.isNull(this._middleware)) {
            this._middleware = (api: MiddlewareAPI<any>) => (next: Dispatch<Action>) => <A extends Action>(action: A): A => {
                if (action.type === PlaySoundType) {
                    const payload: PlaySoundPayload = action.payload;
                    if (Utils.isNotEmpty(payload.name)) {
                        this.play(payload.name).catch(() => {});
                    }
                }
                return next(action);
            };
        }
        return this._middleware;
    }

    public async play(resource: string): Promise<void> {
        if (resource) {
            if (!this._audioCtx) {
                const AudioContext = (window as any).AudioContext || (window as any).webkitAudioContext;
                this._audioCtx = AudioContext ? new AudioContext() : null;
            }
            if (this._audioCtx) {
                const AudioSource = this._audioCtx.createBufferSource();
                const buffer: AudioBuffer = await this.getBuffer(resource);
                AudioSource.buffer = buffer;
                AudioSource.connect(this._audioCtx.destination);
                AudioSource.start(0);
            } else {
                new Audio(resource).play();
            }
        }
    }

    private getBuffer = async (resource: string): Promise<AudioBuffer> => {
        if (resource && this._audioCtx) {
            const buffer: AudioBuffer = this._buffers.get(resource);
            if (buffer) {
                return Promise.resolve(buffer);
            } else {
                const request = new Request(resource);
                const response: Response = await fetch(request);
                const arrBuffer: ArrayBuffer = await response.arrayBuffer();
                const audioBuffer: AudioBuffer = await this._audioCtx.decodeAudioData(arrBuffer);
                this._buffers.set(resource, audioBuffer);
                return Promise.resolve(audioBuffer);
            }
        }
        return Promise.reject();
    }

    public register(name: string, sound: any): void {}
}
