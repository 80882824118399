import {Engine} from "platform/engine/Engine";
import Platform from "platform/Platform";
import {
    LoadLanguage,
    LoadLanguagePayload,
    SetDetectedLangCode,
    SetLangCodePayload
} from "platform/redux/translation/TranslationActions";
import {LangCode} from "platform/enum/LangCode";
import Utils from "platform/util/Utils";
import {
    InitChatPayload,
    NavigateToPayload,
    SetAppReadyPayload,
    SetBrandProps,
    SetOrientation,
    ShowChatPayload
} from "platform/redux/core/CoreActions";
import {Http} from "platform/network/http/Http";
import {BrandProps} from "platform/redux/core/CoreReduxState";
import WebUtil from "platform/util/WebUtil";
import {LSKey} from "platform/storage/Storage";
import {ZenDeskChat} from "platform/chat/ZenDeskChat";
import Script from "platform/network/script/Script";
import {OrientationType} from "platform/enum/OrientationType";
import {EventType} from "platform/enum/EventType";
import {EnvType} from "platform/enum/EnvType";

export default class CoreEngine extends Engine {

    private static _instance: CoreEngine;

    public static instance(): CoreEngine {
        return this._instance || (this._instance = new this());
    }

    public async setup(): Promise<void> {
        await super.setup();
        Platform.environment().addEventListener(EventType.Resize, () => {
            setTimeout(() => {
                if (window.innerHeight && window.innerWidth) {
                    Platform.dispatch(SetOrientation({
                        orientation: window.innerHeight < window.innerWidth ? OrientationType.LANDSCAPE : OrientationType.PORTRAIT
                    }));
                }
            }, 0);
        });
        if (window.innerHeight && window.innerWidth) {
            Platform.dispatch(SetOrientation({
                orientation: window.innerHeight < window.innerWidth ? OrientationType.LANDSCAPE : OrientationType.PORTRAIT
            }));
        }
        const langCode: LangCode = await this.detectLangCode();
        this._logger.info("Detected language code: " + langCode);
        Platform.dispatch(SetDetectedLangCode({langCode}));
        Platform.dispatch(LoadLanguage({langCode}));
    }

    public onAppReady = (payload: SetAppReadyPayload): void => {
        this._logger.debug("Set app ready: " + payload.ready);
    }

    private async detectLangCode(): Promise<LangCode> {
        let urlLangCode: string = WebUtil.urlParam("languageCode") || WebUtil.urlParam("language") || WebUtil.urlParam("lang");
        let browserLangCode: string = navigator && navigator.language && navigator.language.split("-")[0];  // in case of en-us
        if (Utils.isNotEmpty(urlLangCode)) {
            urlLangCode = urlLangCode.toLowerCase().split("-")[0];  // in case of en-us
        }
        if (Utils.isNotEmpty(browserLangCode)) {
            browserLangCode = browserLangCode.toLowerCase();
        }
        const storageLang: string = await Platform.storage().getItem(LSKey.LangCode);
        this._logger.debug("URL lang: " + urlLangCode + " storage lang: " + storageLang + " browser lang: " + browserLangCode);
        let langCode: string = urlLangCode || storageLang || browserLangCode;
        if (Utils.isNotEmpty(langCode)) {
            langCode = langCode.toLowerCase();
        } else {
            langCode = LangCode.EN;
        }
        return Promise.resolve(langCode as LangCode);
    }

    public onLoadLanguage = (payload: LoadLanguagePayload): void => {
        const brandAssetUrl: string = Platform.config().brandAssetUrl;
        if (brandAssetUrl) {
            const curLangCode: LangCode = Platform.reduxState().translation.langCode;
            if (curLangCode !== payload.langCode) {
                this._logger.debug("Start fetch brand props for lang: " + payload.langCode);
                const loadLangProps = (enProps?: BrandProps) => {
                    const mergeProps = (props?: BrandProps) => {
                        const brandProps: BrandProps = {keys: {}};
                        if (enProps?.keys) {
                            brandProps.keys = {...enProps.keys};
                        }
                        if (props?.keys) {
                            brandProps.keys = {...brandProps.keys, ...props.keys};
                        }
                        Platform.dispatch(SetBrandProps({
                            brandProps
                        }));
                    };
                    if (payload.langCode !== LangCode.EN) {
                        Http.getJson(brandAssetUrl + payload.langCode + "/Websites_full.json").then((props: BrandProps) => {
                            mergeProps(props);
                        }).catch(() => {
                            this._logger.warn(`Failed fetch ${payload.langCode} brand props`);
                            mergeProps();
                        });
                    } else {
                        mergeProps();
                    }
                };
                Http.getJson(`${brandAssetUrl}en/Websites_full.json`).then((props: BrandProps) => {
                    loadLangProps(props);
                }).catch(() => {
                    this._logger.warn("Failed fetch EN brand props");
                    loadLangProps();
                });
            }
        }
    }

    public initChat = (payload: InitChatPayload) => {
        if (payload.scriptUrl) {
            if (Platform.environment().type() === EnvType.Web) {
                const scriptUrl: string = decodeURIComponent(payload.scriptUrl);
                this._logger.debug("Init Ze chat by URL: " + scriptUrl);
                ZenDeskChat.detectAndHide(() => {
                    this._logger.debug("Initialize Ze chat");
                    const {langCode} = Platform.reduxState().translation;
                    ZenDeskChat.SetLangCode(langCode);
                });
                Script.injectScript(scriptUrl, "ze-snippet");
            }
        } else {
            this._logger.warn("Can't init Ze chat with empty script URL");
        }
    }

    public onSetLangCode = (payload: SetLangCodePayload) => {
        if (Utils.isNotNull(payload.langCode)) {
            this._logger.debug("Set language to " + payload.langCode);
            const label: string = Platform.config().label;
            ZenDeskChat.setLangCode(payload.langCode, label);
        }
    }

    public showChat = (payload: ShowChatPayload) => {
        ZenDeskChat.show(payload);
    }

    public doNavigateTo = (payload: NavigateToPayload): void => {
        if (Utils.isNotEmpty(payload.route)) {
            this._logger.debug("Navigate to : " + payload.route);
            Platform.router().navigate(payload.route, payload.params);
        } else {
            this._logger.warn("Can't navigate to undefined route");
        }
    }
}
