import Cookies from "js-cookie";
import React from "react";
import ReactDOM from "react-dom/client";
import {AppService} from "core/service/AppService";
import {Service} from "platform/service/Service";
import Platform, {SetupResult} from "platform/Platform";
import ConfigManager from "core/configuration/ConfigManager";
import {TranslationLoaderImpl} from "core/translation/TranslationLoaderImpl";
import WebEnvironment from "platform/environment/WebEnvironment";
import {Router5} from "platform/router/router5/Router5";
import {Routes} from "core/router/Routes";
import WebUtil from "platform/util/WebUtil";
import {PageType} from "enum/PageType";
import Script from "platform/network/script/Script";
import {createMuiTheme} from "@material-ui/core/styles";
import {SetAppReady} from "platform/redux/core/CoreActions";
import {Provider} from "react-redux";
import {ThemeProvider as MuiThemeProvider} from "@material-ui/styles";
import {RouterProvider} from "react-router5";
import {BrokerAsync, BrokerMobileAsync} from "entry/async";
import {TSMap} from "typescript-map";
import {CookieName} from "platform/enum/CookieName";
import {RedirectLogin} from "core/redux/app/AppReduxActions";

const MuiFonts: TSMap<string, string[]> = new TSMap();
MuiFonts.set("profit", ["AvenirNext", "Roboto", "Arial", "sans-serif"]);

const services: Array<{service: Service<any, any>, props?: any}> = [
    {service: AppService.instance()}
];

Platform.setup({
    configurationLoader: ConfigManager.loader(),
    translationLoader: TranslationLoaderImpl.instance(),
    environment: WebEnvironment.instance(),
    router: Router5.instance({
        routes: Routes.routes(),
        defaultRoute: PageType.Broker,
        defaultParams: WebUtil.getParamsToObject()
    }),
    services
}).then(({configuration}: SetupResult) => {
    const brokerName: string = Cookies.get(CookieName.BrokerName);
    if (brokerName) {
        Platform.dispatch(RedirectLogin({brokerName}));
    }

    const isMobile: boolean = WebUtil.isMobile();
    Script.injectLink("/css/" + configuration.brand + (isMobile ? "-mobile" : "") + ".css");
    const muiTheme = createMuiTheme({
        typography: {
            fontFamily: MuiFonts.get(configuration.brand).join(","),
        }
    });

    Platform.dispatch(SetAppReady({ready: true}));
    const root = ReactDOM.createRoot(document.getElementById("RootElement"));
    root.render(
        <Provider store={Platform.store()}>
            <MuiThemeProvider theme={muiTheme}>
                <RouterProvider router={Platform.router().router()}>
                    <>
                        {isMobile ? <BrokerMobileAsync /> : <BrokerAsync />}
                    </>
                </RouterProvider>
            </MuiThemeProvider>
        </Provider>
    );
}).catch((e) => {
});
