import {Route} from "router5";
import {PageType} from "enum/PageType";

export class Routes {

    private static ROUTES: Route[] = [
        {name: PageType.Broker, path: "/broker"}
    ];

    private constructor() {
    }

    public static routes(): Route[] {
        return this.ROUTES;
    }
}
