enum Environment {

    DEV2 = "dev2",
    QA2 = "qa2",
    ST = "st",
    QA_PROD = "qaprod",
    PROD = "prod",
}

namespace Environment {

    export function isDev(): boolean {
        return process.env.ENVIRONMENT === Environment.DEV2;
    }

    export function isProd(): boolean {
        return process.env.ENVIRONMENT === Environment.PROD;
    }

    export function isStProd(): boolean {
        return process.env.ENVIRONMENT === Environment.ST || process.env.ENVIRONMENT === Environment.PROD;
    }

    export function from(env: string): Environment {
        if (env) {
            switch (env) {
                case Environment.DEV2:
                    return Environment.DEV2;
                case Environment.QA2:
                    return Environment.QA2;
                case Environment.ST:
                    return Environment.ST;
                case Environment.QA_PROD:
                    return Environment.QA_PROD;
                case Environment.PROD:
                    return Environment.PROD;
            }
        }
        return null;
    }
}

export {Environment};
