import Utils from "platform/util/Utils";
import {Logger} from "platform/logger/Logger";
import {LangCode} from "platform/enum/LangCode";
import GraphNode from "platform/util/GraphNode";
import Parameter from "platform/util/Parameter";
import {TranslationUnit} from "platform/translation/TranslationUnit";
import {TranslationLoader} from "platform/translation/TranslationLoader";

const REF_PREF: string = "{ref:";
const REF_SUF: string = "}";

export default class Translations {

    private static LOGGER: Logger = Logger.Of("Translations");
    private static TEXTS: object = {};
    private static LANG_CODE: LangCode;
    private static DATA: any;

    private constructor() {
    }

    public static async load(languageCode: LangCode, loader: TranslationLoader): Promise<{langCode: LangCode, data: any}> {
        if (languageCode && Translations.LANG_CODE === languageCode) {
            return Promise.resolve({
                langCode: Translations.LANG_CODE,
                data: Translations.DATA
            });
        } else {
            Translations.LOGGER.info("Loading EN translations ...");
            Translations.TEXTS = {};
            const enAnswer: any = await Utils.to(Translations.loadLanguage(Translations.LANG_CODE = LangCode.EN, loader));
            let error: any = enAnswer[0];
            if (error) {
                return Promise.reject(error);
            } else {
                Translations.DATA = enAnswer[1];
                Translations.LOGGER.info("EN translations loaded. Requested language: " + languageCode);
                if (languageCode && languageCode !== Translations.LANG_CODE) {
                    Translations.LOGGER.info("Loading " + languageCode + " translations ...");
                    const langAnswer = await Utils.to(Translations.loadLanguage(languageCode, loader));
                    error = langAnswer[0];
                    if (error) {
                        Translations.LOGGER.warn("Can't load translation for language " + languageCode + " Error: " + JSON.stringify(error));
                    } else {
                        Translations.LANG_CODE = languageCode;
                        Translations.DATA = langAnswer[1];
                        Translations.LOGGER.info(languageCode + " translations loaded.");
                    }
                }
                Translations.processReferences();
                return Promise.resolve({
                    langCode: Translations.LANG_CODE,
                    data: Translations.DATA
                });
            }
        }
    }

    private static async loadLanguage(langCode: LangCode, loader: TranslationLoader): Promise<any> {
        const answer: any = await Utils.to(loader.load(langCode));
        const error: any = answer[0];
        if (error) {
            return Promise.reject(error);
        } else {
            const payload = answer[1];
            if (Utils.isNotNull(payload.translations)) {
                Object.keys(payload.translations).forEach((key: string) => {
                    Translations.TEXTS[key] = payload.translations[key];
                });
            }
            return Promise.resolve(payload);
        }
    }

    private static processReferences(): void {
        Object.keys(Translations.TEXTS).forEach((key) => {
            const result: string = Translations.insertReferences(GraphNode.create<string>(key), Translations.TEXTS[key], key);
            Translations.TEXTS[key] = result;
        });
    }

    private static insertReferences(node: GraphNode<string>, value: string, key: string): string {
        let result: string = value;
        if (Utils.isNotEmpty(value)) {
            let startIndex: number;
            while ((startIndex = result.indexOf(REF_PREF)) >= 0) {
                const endIndex: number = result.indexOf(REF_SUF, startIndex);
                const referencePattern: string  = result.substring(startIndex, endIndex + 1);
                const referenceKey: string = referencePattern.replace(REF_PREF, "").replace(REF_SUF, "");
                if (node.hasNode(referenceKey)) {
                    Translations.LOGGER.error("Found cyclic references. Translation key: " + key + " Cyclic reference key: " + referenceKey);
                    break;
                }
                const referenceValue: string = Translations.insertReferences(node.addNode(referenceKey, node), Translations.TEXTS[referenceKey], referenceKey);
                result = result.replace(referencePattern, referenceValue);
            }
        }
        return result;
    }

    public static text(key: string, ...parameters: Parameter[]): string {
        return Translations.Text(key, parameters);
    }

    public static textOf(unit: TranslationUnit): string {
        return Translations.Text(unit.trKey, unit.params);
    }

    public static TextOf(unit: TranslationUnit): string {
        if (Utils.isNotEmpty(unit.customValue)) {
            return unit.customValue;
        } else if (Utils.isNotNull(unit.fn)) {
            return unit.fn();
        }
        return Translations.Text(unit.trKey, unit.params);
    }

    public static oneOf(text?: any, unit?: TranslationUnit, key?: string, parameters?: Parameter[]): string {
        if (Utils.isNotNull(text)) {
            return text;
        }
        if (Utils.isNotNull(unit)) {
            return Translations.textOf(unit);
        }
        return Translations.Text(key, parameters);
    }

    public static Text(key: string, parameters: Parameter[]): string {
        if (Utils.isObjectEmpty(Translations.TEXTS)) {
            return "";
        } else {
            return Translations.textOrNull(key, parameters) || key;
        }
    }

    private static textOrNull(key: string, parameters: Parameter[]): string {
        return Translations.replaceParameters(Translations.TEXTS[key], parameters);
    }

    public static hasKey(key: string): boolean {
        return Utils.isNotEmpty(Translations.TEXTS[key]);
    }

    public static translationUnit(key: string, ...parameters: Parameter[]): TranslationUnit {
        return {
            trKey: key,
            params: [...parameters]
        };
    }

    public static replaceParameters(text: string, parameters: Parameter[]): string {
        let result: string = text;
        if (result && Utils.isArrayNotEmpty(parameters)) {
            parameters.forEach((parameter: Parameter) => {
                result = Utils.replaceAll(result, parameter.key, parameter.value);
            });
        }
        return result;
    }
}

export { Translations };
