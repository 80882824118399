import {ActionCreatorDefinition, createAction} from "redux-ts";
import Parameter from "platform/util/Parameter";

export const ExecuteDpkType: string =  "ExecuteDpk";

export interface ExecuteDpkPayload {

    dpk: string;
    params?: Parameter[];
}

export const ExecuteDpk: ActionCreatorDefinition<ExecuteDpkPayload, any> = createAction<ExecuteDpkPayload>(ExecuteDpkType);
