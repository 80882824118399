import Utils from "platform/util/Utils";

export default class Script {

    public static injectScript(url: string, id: string, callback?: () => void): void {
        this.removeScript(id);
        const head: HTMLHeadElement = document.getElementsByTagName("head")[0];
        const script: HTMLScriptElement = document.createElement("script");
        script.type = "text/javascript";
        script.onload = callback;
        script.src = url;
        script.id = id;
        head.appendChild(script);
    }

    private static removeScript(id: string): void {
        const script: any = document.getElementById(id);
        if (Utils.isNotNull(script)) {
            const head: HTMLHeadElement = document.getElementsByTagName("head")[0];
            head.removeChild(script);
        }
    }

    public static injectLink(url: string, id?: string): void {
        const head: any = document.getElementsByTagName("head")[0];
        const link: HTMLLinkElement = document.createElement("link");
        link.type = "text/css";
        link.rel = "stylesheet";
        link.id = id;
        link.href = url;
        head.appendChild(link);
    }
}
