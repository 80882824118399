import {Action, ReducerBuilder} from "redux-ts";
import {Reducer} from "platform/redux/Reducer";
import {AppReduxState} from "core/redux/app/AppReduxState";
import {RedirectLoginType, SelectBroker, SelectBrokerPayload} from "core/redux/app/AppReduxActions";
import AppEngine from "core/engine/AppEngine";
import Platform from "platform/Platform";
import {ServiceType} from "enum/ServiceType";

export default class AppReducer extends Reducer<AppReduxState> {

    private static _instance: AppReducer;

    public static instance(): AppReducer {
        return this._instance || (this._instance = new this());
    }

    public get name(): string {
        return "app";
    }

    private constructor() {
        super();
        const appEngine: AppEngine = Platform.engine(ServiceType.App);
        this._middlewareActions.set(RedirectLoginType, appEngine.doRedirectLogin);
    }

    protected setup(builder: ReducerBuilder<AppReduxState>): void {
        builder
            .init({
            })
            .handle(SelectBroker, (state: AppReduxState, {payload}: Action<SelectBrokerPayload>) => {
                this._logger.debug("Select broker: " + payload.broker.type);
                return Object.assign({}, state, {
                    selectedBroker: payload.broker
                });
            });
    }
}
