import {Middleware, Reducer} from "redux";
import {createRouter, Route, Router, State} from "router5";
import {router5Middleware} from "redux-router5";
import {router5Reducer} from "redux-router5";
import {actions} from "redux-router5";
import browserPlugin from "router5-plugin-browser";
import Utils from "platform/util/Utils";
import Platform from "platform/Platform";
import {INavigationOptions, IRouter} from "platform/router/IRouter";
import {Params} from "router5/dist/types/base";
import {Logger} from "platform/logger/Logger";

export interface Router5Props {

    routes?: Route[];
    defaultRoute?: string;
    defaultParams?: Params;
    allowNotFound?: boolean;
}

export class Router5 implements IRouter {

    private static _instance: Router5;
    private _logger: Logger = Logger.Of("Router5");
    private readonly _props: Router5Props;
    private readonly _router: Router;
    private readonly _middleware: Middleware;
    private lastRouteName: string;
    private lastParams: any
    private lastOpts: INavigationOptions;

    private constructor(props: Router5Props  = {}) {
        this._props = props;
        this._router = createRouter(props.routes || [], {
            defaultRoute: props.defaultRoute || "undefined",
            defaultParams: props.defaultParams || {},
            autoCleanUp: true,
            allowNotFound: props.allowNotFound || true,
        });
        this._router.usePlugin(browserPlugin({
            useHash: false
        }));
        this._middleware = router5Middleware(this._router);
    }

    public static instance(props: Router5Props): Router5 {
        return this._instance || (this._instance = new this(props));
    }

    public reducer(): Reducer<State> {
        return router5Reducer as any;
    }

    public middleware(): Middleware {
        return this._middleware;
    }

    public router(): Router {
        return this._router;
    }

    public navigate(routeName: string, params?: any, opts?: INavigationOptions): void {
        if (this.router().getState()) {
            const defParams = this._props.defaultParams || {};
            const navParams = params || {};
            if (defParams.theme) {
                navParams.theme = defParams.theme;
            }
            this._logger.debug("Navigate to: " + routeName);
            Platform.dispatch(actions.navigateTo(routeName, navParams, opts));
        } else {
            this._logger.debug("Router not ready. Hold navigation to: " + routeName);
            this.lastRouteName = routeName;
            this.lastParams = params;
            this.lastOpts = opts;
        }
    }

    public start(path?: string): Promise<State> {
        return new Promise<any>(
            (resolve, reject) => {
                const done = (err?: any, state?: State) => {
                    if (Utils.isNull(err)) {
                        this._logger.info("Router started with initial state: " + JSON.stringify(state));
                        resolve(state);
                        if (this.lastRouteName) {
                            this._logger.debug("Resume navigation to: " + this.lastRouteName);
                            this.navigate(this.lastRouteName, this.lastParams, this.lastOpts);
                            this.lastRouteName = null;
                            this.lastParams = null;
                            this.lastOpts = null;
                        }
                    } else {
                        this._logger.error("Failed to start router: " + err);
                        reject(err);
                    }
                };
                if (Utils.isNotEmpty(path)) {
                    this._router.start(path, done);
                } else {
                    this._router.start(done);
                }
            }
        );
    }
}
