export enum IntegrationClassId {

    PAGE_READY = 101,
    LOG = 102,
    PING = 108,
    PONG = 109,
    OPEN_EXTERNAL_URL = 111,
    WIN_INIT = 112,
    WIN_INITIALIZED = 113,
    WIN_CLOSE = 114,
    WIN_BEFORE_REDIRECT = 115,
    NAVIGATE_TO = 118,
    SUBMIT_FORM = 119,
    SHOW_LOADING = 120,
    HIDE_LOADING = 121,
    RESIZE = 122,
    EXECUTE_DPK = 123,
    OPEN_CHAT = 125,
    SHOW_BACK_NAVIGATION = 131,
    NAVIGATE_BACK = 132,
    WILL_DO_LATER = 133,
    ORIENTATION_CHANGE = 134,
    SET_THEME = 135,
    THEME_READY = 136,

    // Chart
    CHART_INIT = 200,
    CHART_INITIALIZED = 201,
    CHART_METRICS_CHANGED = 202,
    CHART_NEW_QUOTES = 203,
    CHART_QUOTE_STATE = 204,
    CHART_DEAL_DISPLAY = 205,
    CHART_DEAL_UPDATE = 206,
    CHART_DEAL_EDIT = 207,
    CHART_DEAL_CLOSE = 208,
    CHART_DEAL_UPDATE_SL_TP = 209,
    CHART_DEAL_UPDATE_SL_TP_RESULT = 210,
    CHART_DO_BUY = 211,
    CHART_DO_SELL = 212,
    CHART_MULTI_CHARTS_OPEN = 213,
    CHART_LOAD_SYMBOL = 214,
    CHART_SYMBOL_LOADED = 215,
    CHART_SEARCH_SYMBOL = 216,
    CHART_SHOW_SIGNAL = 217,
}
